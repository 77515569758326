import React, { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import qs from 'qs';
import get, {
  CampaignRealtimeBlacklistRequest,
  CampaignRealtimeBlacklistResponseError,
  CampaignRealtimeBlacklistResponseOk,
  isResponseOK,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import { Tooltip } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import PageContext from '../../utils/context/realtimeBlacklistContext';
import { useParams, useSearchParams } from 'react-router-dom';
import MaterialDataTable from '../../components/RealtimeBlacklist/MaterialDataTable';
import { recognizeCampaignRealtimeBlacklistRequest } from '../../api/campaigns/campaigns.realtime-blacklist.get';
import Filter from '../../components/RealtimeBlacklist/Filter';
import NoConvsModal from '../../components/RealtimeBlacklist/NoConvsModal';
import post, {
  CampaignBundleBanWithRulesPostResponseError, CampaignBundleBanWithRulesPostResponseOk,
  isResponseOK as isResponseBanOK,
} from '../../api/campaigns/campaigns.bundle.ban-with-rules.post';
import BadCpaModal from '../../components/RealtimeBlacklist/BadCpaModal';
import TopBundlesModal from '../../components/RealtimeBlacklist/TopBundlesModal';
import { AiFillQuestionCircle } from 'react-icons/ai';

const RealtimeBlacklistPage = function() {
  const id = parseInt(String(useParams()['id']));
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowsChosen, setRowsChosen] = useState<string[]>([]);
  const [noConvsModalOpened, setNoConvsModalOpened] = useState<boolean>(false);
  const [badCpaModalOpened, setBadCpaModalOpened] = useState<boolean>(false);
  const [topBundlesModalOpened, setTopBundlesModalOpened] = useState<boolean>(false);
  const [response, setResponse] = useState<CampaignRealtimeBlacklistResponseOk>();
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);

  const [filter, setFilter] = useState<CampaignRealtimeBlacklistRequest>(recognizeCampaignRealtimeBlacklistRequest);
  const [submitFilter, setSubmitFilter] = useState<boolean>(false);
  const dataContext = useContext(appContext);

  const requestData = () => {
    dataContext.setIsLoading(true);
    get(id, filter).then(r => {
      if (!isResponseOK(r)) {
        r = r as CampaignRealtimeBlacklistResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as CampaignRealtimeBlacklistResponseOk;
      setResponse(r);
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  };

  const pageName = response?.message.campaign.name ?? 'Статистика по источникам';
  useEffect(() => {
    setSearchParams(qs.stringify(filter, { arrayFormat: 'brackets' }));
    requestData();
    setSubmitFilter(false);
  }, [submitFilter]);


  useEffect(() => {
    const onKeypress = (e: any) => {
      if(e.key === 'Escape') {
        e.preventDefault();
        setRowsChosen([]);
      }
    }

    document.addEventListener('keydown', onKeypress);

    return () => {
      document.removeEventListener('keydown', onKeypress);
    };
  }, []);


  function banWithRules(type: 1 | 2) {
    let request = {};
    if (type === 1) {
      if (filter.minSpend === undefined || filter.maxConvs === undefined) {
        dataContext.notify('Невозможно заблокировать по правилами так как одно из правил не определено', 'error');
        return;
      }
      if (!confirm(`Вы действительно хотите заблокировать все связки с тратами ${filter.minSpend} и выше, где макс. конверсий ${filter.maxConvs} и меньше?`)) {
        return;
      }
      request = {
        minSpend: filter.minSpend,
        maxConvs: filter.maxConvs,
      };
    } else if (type === 2) {
      if (filter.minSpend === undefined || filter.minCpa === undefined) {
        dataContext.notify('Невозможно заблокировать по правилами так как одно из правил не определено', 'error');
        return;
      }
      if (!confirm(`Вы действительно хотите заблокировать все связки с тратами ${filter.minSpend} и выше, где Cpa ${filter.minCpa} и больше?`)) {
        return;
      }
      request = {
        minSpend: filter.minSpend,
        minCpa: filter.minCpa,
      };
    } else {
      alert('Invalid type');
      return;
    }

    dataContext.setIsLoading(true);
    post(response?.message.campaign.id ?? 0, request).then(r => {
      if (!isResponseBanOK(r)) {
        r = r as CampaignBundleBanWithRulesPostResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      dataContext.notify("Связки успешно были заблокированы в соответствии с правилами", 'success');
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
      requestData();
    });
  }

  return (<PageContext.Provider
    value={{ requestData, response: response ?? null }}><Page
    className='RealtimeBlacklistPage'
    title={pageName}
  >
    <AiFillQuestionCircle id={'test'} style={{color: '#00c9ff', fontSize: '25px', float: "right", marginTop: "-50px"}}/>
    <Tooltip placement='left' isOpen={isOpenTooltip} target={'test'}
             toggle={() => setIsOpenTooltip(prev => !prev)}>
      esc - снять выделение строк<br />enter - применить фильтр<br />Если период больше 30 дней, то автоматически проставляется фильтр от 1 лида и 1$ трат
    </Tooltip>
    <Filter setFilter={setFilter}
            filter={filter}
            setSubmitFilter={setSubmitFilter}
            submitFilter={submitFilter}
            rowsChosen={rowsChosen}
            setRowsChosen={setRowsChosen}
            response={response}
            setResponse={(obj: any) => {
              setResponse(obj)
            }
            }/>
    {/*{!dataContext.isLoading &&*/}
    {/*  (response && <div>*/}
    {/*    <button onClick={() => setNoConvsModalOpened(true)} className={`btn btn-sm btn-${(filter.minSpend !== undefined && (filter.maxConvs !== undefined || filter.minConvs !== undefined) ? 'info' : 'primary')}`}>0 лидов</button>*/}
    {/*    <button onClick={() => setBadCpaModalOpened(true)} className={`btn btn-sm btn-${filter.minSpend !== undefined && filter.minCpa !== undefined ? 'info' : 'primary'} ml-1`}>Плохой cpa</button>*/}
    {/*    <button onClick={() => setTopBundlesModalOpened(true)} className={`btn btn-sm btn-${(filter.cpa30 !== undefined && filter.cpa7 !== undefined ? 'info' : 'primary')} ml-1`}>Пушка источники</button>*/}
    {/*    /!*{(response && filter.minSpend !== undefined && (filter.maxConvs !== undefined || filter.minConvs !== undefined) &&*/}
    {/*      <button onClick={() => banWithRules(1)} className='btn btn-sm btn-danger ml-1'>Заблокировать*/}
    {/*        все</button>)}*/}
    {/*    {(response && filter.minSpend !== undefined && filter.minCpa !== undefined &&*/}
    {/*      <button onClick={() => banWithRules(2)} className='btn btn-sm btn-danger ml-1'>Заблокировать*/}
    {/*        все</button>)}*!/*/}
    {/*  </div>)}*/}
    <div className={'col-12 pt-2 pr-0'} style={{clear: "both"}}></div>
    {!dataContext.isLoading &&
      (response && <NoConvsModal isOpen={noConvsModalOpened}
                                 toggle={() => setNoConvsModalOpened(!noConvsModalOpened)}
                                 campaignId={response?.message.campaign.id ?? 0}
                                 desiredCpa={response?.message.campaign.desiredCpa}
                                 setSubmitFilter={setSubmitFilter}
                                 submitFilter={submitFilter}
                                 filter={filter} setFilter={setFilter} />)}
    {!dataContext.isLoading &&
      (response && <BadCpaModal isOpen={badCpaModalOpened}
                                toggle={() => setBadCpaModalOpened(!badCpaModalOpened)}
                                campaignId={response?.message.campaign.id ?? 0}
                                setSubmitFilter={setSubmitFilter}
                                submitFilter={submitFilter}
                                filter={filter} setFilter={setFilter} />)}
    {!dataContext.isLoading &&
      (response && <TopBundlesModal isOpen={topBundlesModalOpened}
                                toggle={() => setTopBundlesModalOpened(!topBundlesModalOpened)}
                                campaignId={response?.message.campaign.id ?? 0}
                                setSubmitFilter={setSubmitFilter}
                                submitFilter={submitFilter}
                                filter={filter} setFilter={setFilter} />)}
    {!dataContext.isLoading &&
      (response && <MaterialDataTable
        setFilter={setFilter}
        setSubmitFilter={setSubmitFilter}
        submitFilter={submitFilter}
        rowsChosen={rowsChosen}
        setRowsChosen={setRowsChosen}
        filter={filter}
        response={response}
        setResponse={(obj: any) => {
          setResponse(obj)
        }
        }
      />)}
  </Page></PageContext.Provider>);
};
export default RealtimeBlacklistPage;
