//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';
import { formatDate, getDateNDaysAgo } from '../../utils/date';
import { DateTime } from 'luxon';

const PATH_URL = '/campaigns/{id}/analytics';

export type CampaignAnalyticsAbsoluteIndicatorRequest = {
  domainId?: number,
  startDate?: string,
  endDate?: string,
  tagid?: string,
  groupBy?: 'hour' | 'day',
}


export function recognizeCampaignAnalyticsRequest(): CampaignAnalyticsAbsoluteIndicatorRequest {
  const dateStartStr = DateTime.fromJSDate(getDateNDaysAgo(14)).toFormat('yyyy-MM-dd');
  const dateEndStr = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  const r = { startDate: dateStartStr, endDate: dateEndStr , groupBy: 'hour'};
  const uri = window.location.search.split('?');
  if (uri[1] === undefined) return r;
  const params = qs.parse(uri[1], { allowSparse: true, arrayLimit: 1000 });
  for (const key in params) {
    if(key === 'startDate' || key === 'endDate') {
      r[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
      if (r[key] === 'Invalid DateTime') {
        r[key] = DateTime.fromFormat(params[key], 'yyyy-M-dd').toFormat('yyyy-MM-dd');
      }
      if (r[key] === 'Invalid DateTime') {
        if(key === 'startDate') {
          r[key] = dateStartStr;
        } else {
          r[key] = dateEndStr;
        }
      }
      continue;
    }
    // @ts-ignore
    r[key] = params[key];
  }
  return r;
}

export type CampaignAnalyticsAbsoluteIndicatorResponseOk = {
  'status': number,
  'message': CampaignAnalyticsAbsoluteIndicatorResponseMessageOk,
}

export type CampaignAnalyticsAbsoluteIndicatorResponseMessageOk = {
  data: CampaignAnalyticsAbsoluteIndicatorStat,
  tags: CampaignAnalyticsAbsoluteIndicatorTags[],
  topAdSlotsByDrawdown: CampaignAnalyticsAbsoluteIndicatorAdSlots[],
  topAdSlotsByGrowth: CampaignAnalyticsAbsoluteIndicatorAdSlots[]
}

export type CampaignAnalyticsAbsoluteIndicatorAdSlots = {
  domainId: number,
  tagid: string,
  lastDateSpend: number,
  lastDateProfit: number,
  periodSpend: number,
  periodProfit: number,
  profitDevaitionPercent: number,
  profitDeviationAbsolute: number
}

export type CampaignAnalyticsAbsoluteIndicatorTags = {
  app: boolean
  domainId: number
  inBL: boolean
  inWL: boolean
  rate: number
  systemId: number
  tagid: string
}


export type CampaignAnalyticsAbsoluteIndicatorResponseError = {
  'status': number,
  'message': CampaignAnalyticsAbsoluteIndicatorResponseMessageError,
}

export type CampaignAnalyticsAbsoluteIndicatorResponseMessageError = string

// @ts-ignore
const key = new apiKey();

function getUrl(id: number): string {
  return (process.env.REACT_APP_API_URL + PATH_URL).replace('{id}', id);
}

export default function get(id:number, request: CampaignAnalyticsAbsoluteIndicatorRequest = null): Promise<CampaignAnalyticsAbsoluteIndicatorResponseOk | CampaignAnalyticsAbsoluteIndicatorResponseError> {
  return axios.get(getUrl(id), {
    params: request,
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <CampaignAnalyticsAbsoluteIndicatorResponseOk | CampaignAnalyticsAbsoluteIndicatorResponseError>r.data;
  }).catch(e => {
    const resp: CampaignAnalyticsAbsoluteIndicatorResponseError = {
      status: 0,
      message: e.response?.data?.message ?? e.message,
    };
    return resp;
  });
}

export function isResponseOK(response: CampaignAnalyticsAbsoluteIndicatorResponseOk | CampaignAnalyticsAbsoluteIndicatorResponseError): boolean {
  return typeof response.message === 'object';
}